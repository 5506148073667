import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Accordion, Badge, Card, Col, Container, FormControl, FormLabel, FormSelect, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import UserFinder from '../components/userFinder';
import UserName from '../components/userName';
import { updateShow } from '../utils/reducers/showsSlice';
import './show.css';

export default function Show({ api }) {

    const { id: showID } = useParams();

    const shows = useSelector(state => state.shows.shows);
    const profile = useSelector(state => state.profile.profile);
    const users = useSelector(state => state.users.users);

    const [recordings, setRecordings] = useState([]);
    const [logs, setLogs] = useState([]);

    const showDetails = shows.find(s => s?.ShowID === parseInt(showID));
    const [tempShowDetails, setTempShowDetails] = useState(null);

    const navigate = useNavigate();
    const reduxDispatch = useDispatch();

    const [selectedMoveShowID, setSelectedMoveShowID] = useState('');

    useEffect(() => {
        document.title = process.env.REACT_APP_PAGE_TITLE + 'Edit Show';
        setTempShowDetails(showDetails);

        // Get show recordings
        setRecordings(null);
        api.getShowRecordings(showID).then(recordings => {
            setRecordings(recordings);
        }).catch(error => {
            toast.error(error.message);
            console.error(error);
        });

        // Get show logs
        setLogs(null);
        if (profile?.Role !== 3) api.getShowLogs(showID).then(logs => {
            setLogs(logs);
        }).catch(error => {
            toast.error(error.message);
            console.error(error);
        });
    }, [showDetails?.ShowID !== parseInt(showID), showDetails?.ShowID !== tempShowDetails?.ShowID]);

    if (!showDetails || !tempShowDetails) return (<div></div>);

    const pendingChangesTitle = 'Changes are pending to be saved...';

    if (showDetails === undefined || tempShowDetails === undefined) return <div />;

    const hasChanged = (key) => {
        if (key === undefined) {
            let changeNoticed = false;
            Object.keys(showDetails).forEach((oKey) => {
                if (changeNoticed) return;
                if ((showDetails && tempShowDetails) && showDetails[oKey] !== tempShowDetails[oKey]) changeNoticed = true;
            });
            return changeNoticed;
        }
        return showDetails[key] !== tempShowDetails[key];
    };

    const showArchived = showDetails?.Archived === 1;

    const updateShowDetails = () => {
        if (showArchived) return toast.error('Cannot update archived shows.');
        api.updateShowDetails(tempShowDetails?.ShowID, tempShowDetails.Name, tempShowDetails.Host, tempShowDetails.Description, tempShowDetails.Record, tempShowDetails.Instagram).then(result => {
            if (result) {
                toast.success('Show Updated successfully.');
                // Check if tempShowDetails.Instagram starts with a @, and if it does remove it.
                if (tempShowDetails.Instagram.startsWith('@')) tempShowDetails.Instagram = tempShowDetails.Instagram.substring(1);
                reduxDispatch(updateShow({
                    id: tempShowDetails.ShowID,
                    Name: tempShowDetails.Name,
                    Host: tempShowDetails.Host,
                    Description: tempShowDetails.Description,
                    Record: tempShowDetails.Record,
                    Instagram: tempShowDetails.Instagram,
                }));
                if (profile.Role !== 3) navigate('/shows/list');
            }
            else toast.error('Show details were not updated.');
        }).catch(error => {
            console.warn(error);
            toast.error('Could not update: ' + error.message);
        });
    };

    const handleRecordingArchive = (recordingID) => {
        api.archiveRecording(showDetails?.ShowID, recordingID).then(result => {
            if (result) {
                toast.success('Recording archived successfully.');
                setRecordings(recs => [...recs.filter(r => r.RecordingID !== recordingID), { ...recs.find(r => r.RecordingID === recordingID), Archived: 1 }].sort((a, b) => b.RecordingID - a.RecordingID));
            }
            else toast.error('Recording was not archived.');
        }).catch(error => {
            console.warn(error);
            toast.error('Could not archive: ' + error.message);
        });
    };

    const handleRecordingRestore = (recordingID) => {
        api.restoreRecording(showDetails?.ShowID, recordingID).then(result => {
            if (result) {
                toast.success('Recording restored successfully.');
                setRecordings(recs => [...recs.filter(r => r.RecordingID !== recordingID), { ...recs.find(r => r.RecordingID === recordingID), Archived: 0 }].sort((a, b) => b.RecordingID - a.RecordingID));
            }
            else toast.error('Recording was not restored.');
        }).catch(error => {
            console.warn(error);
            toast.error('Could not restore: ' + error.message);
        });
    };

    const handleMoveShowChange = (e) => {
        setSelectedMoveShowID(e.target.value);
    };

    const handleRecordingMove = (recordingID) => {
        if (selectedMoveShowID != '') {
            api.moveRecording(showDetails?.ShowID, recordingID).then(result => {
                if (result) {
                    toast.success('Recording moved successfully.');
                    setRecordings(recs => [...recs.filter(r => r.RecordingID !== recordingID), { ...recs.find(r => r.RecordingID === recordingID), Archived: 0 }].sort((a, b) => b.RecordingID - a.RecordingID));
                }
                else toast.error('Recording was not move.');
            }).catch(error => {
                console.warn(error);
                toast.error('Could not move: ' + error.message);
            });
        }
    };

    /*

<Col xs={8}>
                                            <FormControl 
                                                type="file" 
                                                onChange={(e) => setTempShowDetails(oS => { 
                                                    return { ...oS, showImage: e.target.files[0] }; 
                                                })} 
                                                accept=".png, .jpg, .jpeg"
                                                isValid={hasChanged('showImage')} 
                                                title={hasChanged('showImage') ? pendingChangesTitle : null} 
                                            />
                                        </Col>

    */

    return (
        <Container className="my-4" key={tempShowDetails?.ShowID}>
            <h1>Show: {showDetails?.Name} by {showDetails?.Host ? <UserName uid={(profile.Role === 3 && profile.UserID !== showDetails?.Host ? profile.UserID : showDetails?.Host)} /> : '...'}{showArchived && ' (Archived)'}</h1>
            <hr />
            {showDetails !== undefined &&
                <>
                    <Card>
                        <Card.Header>
                            <h4 className="d-inline-block">Show Details</h4>
                            {hasChanged() && <button className="btn btn-warning float-end" onClick={updateShowDetails}>Save</button>}
                        </Card.Header>
                        <Card.Body>
                            <Row id="showDetails" className="text-center col-lg-9 mx-lg-auto">
                                <Col md={12} lg={6}>
                                    <Row>
                                        <Col xs={4}><FormLabel>Show Name:</FormLabel></Col>
                                        <Col xs={8}><FormControl className="d-inline-block" defaultValue={tempShowDetails?.Name || 'loading...'} onChange={(e) => setTempShowDetails(oS => { return { ...oS, Name: e.target.value }; })} isValid={hasChanged('Name')} title={hasChanged('Name') ? pendingChangesTitle : null} /></Col>
                                    </Row>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Row>
                                        <Col xs={4}><FormLabel>Host:</FormLabel></Col>
                                        <Col xs={8}>
                                            {profile.Role === 3 && <FormControl className="d-inline-block" defaultValue={profile?.Name || 'loading...'} readOnly />}
                                            {profile.Role !== 3 &&
                                                <UserFinder includeOnly={users} placeholder="Change the show's host..." onUserSelected={newHost => setTempShowDetails(oS => { return { ...oS, Host: newHost.id }; })} />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} lg={12}>
                                    <Row>
                                        <Col xs={4} lg={2}><FormLabel>Description:</FormLabel></Col>
                                        <Col xs={8} lg={10}><FormControl className="d-inline-block" as="textarea" maxLength={256} defaultValue={showDetails.Description} placeholder="No description yet..." onChange={(e) => setTempShowDetails(oS => { return { ...oS, Description: e.target.value }; })} isValid={hasChanged('Description')} title={hasChanged('Description') ? pendingChangesTitle : null} /></Col>
                                    </Row>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Row>
                                        <Col xs={4}><FormLabel>Created On:</FormLabel></Col>
                                        <Col xs={8}><FormControl className="d-inline-block" readOnly disabled value={showDetails?.CreatedDate ? moment(showDetails.CreatedDate * 1000).format('DD/MM/YYYY') : 'loading...'} /></Col>
                                    </Row>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Row>
                                        <Col xs={4}><FormLabel>Allow Recording:</FormLabel></Col>
                                        <Col xs={8}>
                                            <FormSelect className="d-inline-block" defaultValue={showDetails.Record} onChange={(e) => setTempShowDetails(oS => { return { ...oS, Record: parseInt(e.target.value) }; })} isValid={hasChanged('Record')} title={hasChanged('Record') ? pendingChangesTitle : null}>
                                                <option value={1}>Yes</option>
                                                <option value={0}>No</option>
                                            </FormSelect>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Row>
                                        <Col xs={4}><FormLabel>Show Image:</FormLabel></Col>
                                        <Col xs={8}><FormControl className="d-inline-block" readOnly value={'TBD...'} /></Col>
             
                                    </Row>
                                </Col>
                                <Col md={12} lg={6}>
                                    <Row>
                                        <Col xs={4}><FormLabel>Instagram:</FormLabel></Col>
                                        <Col xs={8}><FormControl className="d-inline-block" placeholder='pretty.username' defaultValue={tempShowDetails?.Instagram} onChange={(e) => setTempShowDetails(oS => { return { ...oS, Instagram: e.target.value }; })} isValid={hasChanged('Instagram')} title={hasChanged('Instagram') ? pendingChangesTitle : null} /></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <h4 className="d-inline-block">Show Recordings</h4>
                            <small> 📢 Archiving/restoring recordings only affects their online visibility. Shows will never be deleted during their mandatory retention period or without approval from the Station Manager & Head of Tech.</small>
                        </Card.Header>
                        <Card.Body>
                            {recordings?.length > 0 ? <ul>
                                {recordings.map((recording, index) => {
                                    const duration = moment(0).utc().add(recording.Duration, 'seconds').format('HH:mm:ss');
                                    const submittedOn = moment(recording.Submitted * 1000).format('YYYY-MM-DD @ HH:mm');
                                    const episodeNumber = recordings?.length - index;
                                    const recordingStart = moment(recording.RecordingStart * 1000).format('YYYY-MM-DD @ HH:mm');
                                    return (
                                        <li key={recording.RecordingID}>
                                            <span title={`Uploaded on: ${submittedOn}. Duration: ${duration}`} style={{ marginRight: 10 }}>
                                                Episode #{episodeNumber} - {recordingStart}
                                            </span>
                                            <a href={`https://archive.nervemedia.org.uk/shows/${recording.FileName}`} download target={'_blank'} rel="noreferrer"><Badge bg='secondary'>Listen or Download</Badge></a>
                                            {!recording.Archived ?
                                                <span className='recordingArchive' onClick={() => handleRecordingArchive(recording.RecordingID)}>Archive</span> :
                                                <span className='recordingRestore' onClick={() => handleRecordingRestore(recording.RecordingID)}>Restore</span> 
                                                
                                            }
                                            { /* Admins only - DISABLED CURRENTLY AS WORK IN PROGRESS */ }
                                            {profile.Role == 3 && false &&
                                                <Col md={12} lg={12}>
                                                    <Row>
                                                        <Col xs={4} lg={1}><FormLabel>Move to:</FormLabel></Col>
                                                        <Col xs={4} lg={3}>
                                                            <FormSelect className="d-inline-block" id={'showsMove' + recording.RecordingID} value={selectedMoveShowID} onChange={handleMoveShowChange}>
                                                                <option value="" selected="selected" disabled>Select a show...</option>
                                                                {shows.map(show => (
                                                                    <option key={show.ShowID} value={show.ShowID}>{show.Name}</option>
                                                                ))}
                                                            </FormSelect>
                                                        </Col>
                                                        <Col xs={4} lg={2}>
                                                            <span className='recordingMove' onClick={() => handleRecordingMove(recording.recordingID)}>Confirm move</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </li>
                                    );
                                })}
                            </ul> : recordings !== null ? <p>No recordings yet.</p> : <p>Loading...</p>}
                        </Card.Body>
                    </Card>
                    {profile.Role !== 3 &&
                        <Accordion className="mb-3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <h4>Edit History</h4>
                                    <small style={{ marginLeft: 5 }}> 👀 This can only be seen by Committee and Admins. It includes all changes performed by all users</small>
                                </Accordion.Header>
                                <Accordion.Body>
                                    {logs?.length > 0 ? <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                                        {logs.map((log, index) => {
                                            const timestamp = moment(log.Timestamp * 1000).format('YYYY-MM-DD @ HH:mm:ss');
                                            return (
                                                <li key={index} style={{ borderBottom: '1px solid #6666' }}>
                                                    <small style={{ color: '#999' }}>{timestamp}</small>
                                                    <span style={{ marginLeft: 10 }}>
                                                        <span style={{ color: 'crimson' }}>{users.find(u => u.UserID === log.UserID)?.Name || log.UserID}</span>: {log.Message}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul> : logs !== null ? <p>No logs yet.</p> : <p>Loading...</p>}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }
                </>
            }
        </Container>
    );
}